import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './routes/home';
import './App.css';

function App() {
  return (
    <div className="App">

<Home />

    </div>
  );
}

export default App;
