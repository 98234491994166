import Landing from '../components/landing';

function Home() {
  return (
	<Landing />
  );
}

export default Home;

